import { useParams } from 'react-router-dom';
import CandidateRequestView from '../components/RequestView/CandidateRequestView';
import { withAuthRequired } from '../components/ProtectedRoute';
import ClientRequestView from '../components/RequestView/ClientRequestView';
import UnsubscribeView from '../components/UnsubscribeView';

export const ProfileRoute = () => {
	const { token } = useParams();
	return <CandidateRequestView token={token!} />;
};

export const ClientRoute = withAuthRequired(() => {
	const { token } = useParams();
	return <ClientRequestView token={token!} />;
});

export const UnsubscribeRoute = () => {
	const { token } = useParams();
	return <UnsubscribeView token={token!} />;
};
