import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Layout from './components/Layout';
import { theme } from './themes/ThemeSelector';
import { Theme } from './types';
import { ClientRoute, ProfileRoute, UnsubscribeRoute } from './routes';

const _getFavicon = () => {
	switch (theme) {
		case Theme.LOX_CLUB: {
			return '/images/loxclub_favicon.ico';
		}
		case Theme.JADE_CLUB: {
			return '/images/jadeclub_favicon.ico';
		}
		case Theme.AMARA_CLUB: {
			return '/images/amaraclub_favicon.ico';
		}
	}
};

const router = createBrowserRouter([
	{
		children: [
			{
				path: 'profile/:token/*',
				element: <ProfileRoute />,
			},
			{
				path: 'client/:token/*',
				element: <ClientRoute />,
			},
			{
				path: 'unsubscribe/:token/*',
				element: <UnsubscribeRoute />,
			},
		],
		errorElement: <ErrorPage />,
	},
]);

function App() {
	return (
		<HelmetProvider>
			<Helmet>
				<link rel="icon" href={_getFavicon()} />
			</Helmet>
			<Layout>
				<RouterProvider router={router} />
			</Layout>
		</HelmetProvider>
	);
}

export default App;
