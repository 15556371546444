import { useState } from 'react';
import { Col, Row, Form, Button, Alert, Container } from 'react-bootstrap';
import { ConciergeInterestRequestNegativeFeedback } from '../../api/profile';

const MAX_SELECTIONS = 3;
const MAX_LENGTH = 40;

const DisinterestedView = ({
	showSurvey,
	logNegativeFeedback,
}: {
	showSurvey: boolean;
	logNegativeFeedback: (
		selectedReasons: (ConciergeInterestRequestNegativeFeedback | string)[]
	) => void;
}) => {
	const [selectedReasons, setSelectedReasons] = useState<
		(ConciergeInterestRequestNegativeFeedback | string)[]
	>([]);
	const [additionalDetails, setAdditionalDetails] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const toggleReason = (reason: ConciergeInterestRequestNegativeFeedback) => {
		if (selectedReasons.includes(reason)) {
			setSelectedReasons((prev) => prev.filter((r) => r !== reason));
			setError('');

			if (reason === ConciergeInterestRequestNegativeFeedback.OTHER) {
				setAdditionalDetails('');
			}
		} else if (selectedReasons.length < MAX_SELECTIONS) {
			setSelectedReasons((prev) => [...prev, reason]);
		} else {
			setError(`You can only select up to ${MAX_SELECTIONS} reasons.`);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const feedback = selectedReasons.includes(ConciergeInterestRequestNegativeFeedback.OTHER)
				? [
						...selectedReasons.filter((r) => r !== ConciergeInterestRequestNegativeFeedback.OTHER),
						additionalDetails,
				  ]
				: selectedReasons;
			await logNegativeFeedback(feedback);
		} catch (error) {
			console.log('Error logging feedback', error);
		} finally {
			setLoading(false);
		}
	};

	if (!showSurvey) {
		return (
			<Row className="justify-content-center py-4">
				<Col xs={12} className="mb-4">
					Your response has been recorded.
				</Col>
			</Row>
		);
	}

	return (
		<Container>
			<Row className="justify-content-center py-4">
				<Col xs={12} md={6} className="mb-4">
					<p className="text-center">
						Could you share why this match didn’t feel right? Your feedback helps us share better
						matches in the future.
					</p>
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col xs={12} md={6} className="text-start">
					<Form>
						{Object.values(ConciergeInterestRequestNegativeFeedback).map((reason) => (
							<Form.Check
								key={reason}
								type="checkbox"
								id={`disinterest-${reason}`}
								name="disinterestReasons"
								label={reason}
								value={reason}
								checked={selectedReasons.includes(reason)}
								onChange={() => toggleReason(reason)}
								className={`mb-3 custom-checkbox ${
									selectedReasons.length >= MAX_SELECTIONS && !selectedReasons.includes(reason)
										? 'disabled-checkbox'
										: ''
								}`}
								disabled={
									selectedReasons.length >= MAX_SELECTIONS && !selectedReasons.includes(reason)
								}
							/>
						))}

						<div
							className={
								selectedReasons.includes(ConciergeInterestRequestNegativeFeedback.OTHER)
									? 'fade-in'
									: 'fade-out'
							}
						>
							<Form.Group controlId="additionalDetails" className="mt-3">
								<Form.Label>Please specify</Form.Label>
								<Form.Control
									as="textarea"
									rows={5}
									value={additionalDetails}
									onChange={(e) => setAdditionalDetails(e.target.value)}
									placeholder="Tell us more..."
									maxLength={MAX_LENGTH}
								/>
							</Form.Group>
							<Form.Text muted>{`${
								MAX_LENGTH - additionalDetails.length
							} characters remaining`}</Form.Text>
						</div>
						{/* Display Error Message */}
						{error && <Alert variant="danger">{error}</Alert>}

						<Button
							variant="primary"
							className="mt-4 d-block mx-auto"
							onClick={handleSubmit}
							disabled={
								selectedReasons.length === 0 ||
								loading ||
								(selectedReasons.includes(ConciergeInterestRequestNegativeFeedback.OTHER) &&
									!additionalDetails.trim())
							}
						>
							Submit{loading && 'ting...'}
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default DisinterestedView;
