export const unsubscribe = async (
	token: string,
	email: string,
	feedback: string,
	resubscribeAt?: number
): Promise<{ success: boolean }> => {
	try {
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/unsubscribe`;

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify({
				email,
				feedback,
				resubscribeAt,
			}),
		});
		if (response.ok || response.status === 400) {
			return await response.json();
		}
		return { success: false };
	} catch (e) {
		console.log('failed to register interest', e);
		return { success: false };
	}
};
