import React, { useState } from 'react';
import { Col, Row, Form, Button, Container, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useUnsubscribeModel } from './model';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

enum UnsubscribeReason {
	IN_A_RELATIONSHIP = "I'm not single",
	NOT_LOOKING_TO_DATE = "I'm not looking to date right now",
	TOO_MANY_EMAILS = 'I receive too many emails',
	NOT_INTERESTED = "I'm not interested in matchmaking",
}

const UnsubscribeView: React.FC<{ token: string }> = ({ token }) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [selectedReason, setSelectedReason] = useState<UnsubscribeReason | null>(null);
	const [temporaryUnsubscribe, setTemporaryUnsubscribe] = useState(true); // Default checked
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean>(false);
	const { unsubscribe } = useUnsubscribeModel();

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (!email || !selectedReason) {
			return;
		}
		setLoading(true);
		//Google cloud tasks has a 30 day scheduling limit
		const resubscribeAt = temporaryUnsubscribe
			? DateTime.now().plus({ days: 29, hours: 23 }).toMillis()
			: undefined;
		unsubscribe(token, email, selectedReason, resubscribeAt).then(({ success }) => {
			setLoading(false);
			setError(!success);
			setSubmitted(true);
		});
	};

	if (submitted) {
		return (
			<Container className="py-5">
				<Row className="justify-content-center text-center">
					<Col xs={12}>
						{error ? (
							<>
								<h4>Something went wrong</h4>
								<p>Please reach out to support at {t('matchmakerEmail')}.</p>
							</>
						) : (
							<>
								<h4>You’ve been unsubscribed</h4>
								<p>
									If this was a mistake, feel free to reach out to support at {t('matchmakerEmail')}
									.
								</p>
							</>
						)}
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<>
			<Helmet>
				<title>Unsubscribe</title>
			</Helmet>
			<Container className="py-5" style={{ maxWidth: '500px' }}>
				<Row className="justify-content-center">
					<Col xs={12}>
						<h4 className="text-center mb-4">Unsubscribe</h4>
						<Form onSubmit={handleSubmit}>
							<Form.Group controlId="unsubscribeEmail" className="mb-4">
								<Form.Label>Email Address</Form.Label>
								<Form.Control
									type="email"
									placeholder="jane@acme.com"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
									disabled={loading}
								/>
							</Form.Group>
							<Form.Group controlId="unsubscribeReason" className="mb-4">
								{Object.values(UnsubscribeReason).map((reason) => (
									<Form.Check
										key={reason}
										type="radio"
										id={`unsubscribe-reason-${reason}`}
										name="unsubscribeReason"
										label={reason}
										value={reason}
										checked={selectedReason === reason}
										onChange={() => setSelectedReason(reason as UnsubscribeReason)}
										className="mb-2"
										disabled={loading}
									/>
								))}
							</Form.Group>
							<hr className="my-4" />
							<Form.Group controlId="unsubscribeOption" className="mb-4">
								{[
									{
										label: 'Pause emails for a month',
										value: true,
									},
									{ label: 'Unsubscribe', value: false },
								].map((option) => (
									<Form.Check
										key={option.value.toString()}
										type="radio"
										id={`unsubscribe-option-${option.value}`}
										name="unsubscribeOption"
										label={option.label}
										value={option.value.toString()}
										checked={temporaryUnsubscribe === option.value}
										onChange={() => setTemporaryUnsubscribe(option.value)}
										className="mb-2"
										disabled={loading}
									/>
								))}
							</Form.Group>
							<div className="text-center">
								<Button
									type="submit"
									variant="danger"
									disabled={!email.trim() || !selectedReason || loading}
								>
									{loading && (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
											className="me-2"
										/>
									)}
									Unsubscribe
								</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default UnsubscribeView;
