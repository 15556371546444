import useCandidateRequestViewModel from './model';
import type { FC } from 'react';
import { ConciergeInterestRequestStatus } from '../../../api/profile';
import LoadingView from '../LoadingView';
import ClosedView from '../ClosedView';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ProfileView from '../ProfileView';
import InvalidUrlView from '../InvalidUrlView';
import DisinterestedView from '../DisinterestedView';

const _candidateHasResponded = (requestStatus: ConciergeInterestRequestStatus): boolean => {
	switch (requestStatus) {
		case ConciergeInterestRequestStatus.OPEN:
		case ConciergeInterestRequestStatus.FOLLOW_UP_SENT:
			return false;
		case ConciergeInterestRequestStatus.INTERESTED:
		case ConciergeInterestRequestStatus.NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_CONTACTED:
		case ConciergeInterestRequestStatus.CLIENT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CONNECTED:
		case ConciergeInterestRequestStatus.CLOSED:
			return true;
	}
};

const CandidateRequestView: FC<{ token: string }> = ({ token }) => {
	const { t } = useTranslation();
	const { loading, data, registerInterest, logNegativeFeedback } = useCandidateRequestViewModel({
		token,
	});

	const { status: requestStatus, vettingCallLink, candidateFeedback } = data?.metadata || {};

	if (loading) {
		return <LoadingView />;
	}

	if (requestStatus === ConciergeInterestRequestStatus.CLOSED) {
		return <ClosedView />;
	}

	if (requestStatus && _candidateHasResponded(requestStatus)) {
		return (
			<>
				<Helmet>
					<title>{`${t('matchmaking_name')} - Response Recorded`}</title>
				</Helmet>
				<Row>
					<Col className="text-center p-5">
						Thanks for getting back to us.
						<br />
						<br />
						{requestStatus === ConciergeInterestRequestStatus.NOT_INTERESTED ? (
							<DisinterestedView
								showSurvey={candidateFeedback === undefined}
								logNegativeFeedback={logNegativeFeedback}
							/>
						) : (
							<>
								We recommend scheduling your{' '}
								<a
									href={vettingCallLink}
									target="_blank"
									rel="noopener noreferrer"
									style={{ textDecoration: 'underline', color: 'inherit' }}
								>
									5 min intro call
								</a>{' '}
								within 3 days to help us confirm it’s a great match.
								<br />
								<br />
								<span style={{ fontSize: 14, color: '#b9b8b8' }}>
									If you have any questions, feel free to email {t('matchmakerEmail')}.
								</span>
							</>
						)}
						{data && <ProfileView data={data.profile!} status={data.metadata.status} />}
					</Col>
				</Row>
			</>
		);
	}

	if (!data) {
		return <InvalidUrlView />;
	}

	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - ${data.profile?.firstName}`}</title>
			</Helmet>
			<ProfileView
				data={data.profile!}
				registerInterest={registerInterest}
				status={data.metadata.status}
			/>
		</>
	);
};

export default CandidateRequestView;
