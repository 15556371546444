import { FC } from 'react';
import { type ProfileViewParams } from './types';
import { Row, Col, Button, Carousel } from 'react-bootstrap';
import { toFeetAndInches, uppercaseFirstLetter } from '../../utils/strings';
import { ConciergeInterestRequestStatus } from '../../api/profile';

const ProfileView: FC<ProfileViewParams> = ({ data, registerInterest, status }) => {
	if (!data) return null;

	const profileDetails = [
		{ label: 'Work', value: data.profession },
		{ label: 'School', value: data.education },
		{
			label: 'Religion',
			value: data.religion !== 'not_provided' ? uppercaseFirstLetter(data.religion) : null,
		},
		{ label: 'Career and Ambitions', value: data.career },
		{ label: 'Loves', value: data.loves.length > 0 ? data.loves.join(', ') : null },
		{ label: 'Hates', value: data.hates.length > 0 ? data.hates.join(', ') : null },
		{
			label: 'First Date Suggestions',
			value: data.firstDateSuggestions.length > 0 ? data.firstDateSuggestions.join(', ') : null,
		},
	];
	return (
		<Row className="justify-content-center">
			<Col md={8} xs={12} className="d-grid gap-3 mx-auto p-4 profileContentContainer">
				<Carousel interval={null}>
					{data.profileImages.map((item, index) => {
						return (
							<Carousel.Item key={`${index}`}>
								<div className="image-placeholder">
									<img
										src={item}
										alt={`profile headshot ${index + 1}`}
										className="img-fluid"
										style={{ maxHeight: '400px' }}
									/>
								</div>
							</Carousel.Item>
						);
					})}
				</Carousel>
				<h4 className="text-center">{data.firstName}</h4>
				{registerInterest && (
					<Row className="justify-content-center">
						<Col md={8} xs={12} className="text-center mb-2">
							<Button className="eventPillButton w-100 mb-2" onClick={() => registerInterest(true)}>
								Interested
							</Button>
							<div className="mb-4">
								{[
									ConciergeInterestRequestStatus.OPEN,
									ConciergeInterestRequestStatus.FOLLOW_UP_SENT,
								].includes(status) && (
									<p
										className="subtext"
										style={{
											color: '#6c757d',
											fontSize: '14px',
										}}
									>
										After confirming interest, you'll schedule a 5-minute intro call with{' '}
										{data.firstName}'s matchmaker.
									</p>
								)}
							</div>

							<Button className="eventPillButton w-100" onClick={() => registerInterest(false)}>
								Not Interested
							</Button>
						</Col>
					</Row>
				)}
				<Row className="justify-content-center">
					<Col md={8} sm={12} className="profileInfoContainer p-5">
						<Row className="profileInfoContainerCol text-center mb-3">
							<Col xs={2}>{data.age}</Col>
							<Col>{data.location.replace(', USA', '')}</Col>
							{data.heightCm && <Col xs={2}>{toFeetAndInches(data.heightCm)}</Col>}
						</Row>
						{profileDetails
							.filter(({ value }) => value) // Filter out null or empty values
							.map(({ label, value }, index) => (
								<Row key={index} className="profileInfoContainerCol mb-3">
									<Col xs={12}>
										<div className="profileLabel text-start">{label}</div>
										<div className="text-start">{value}</div>
									</Col>
								</Row>
							))}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ProfileView;
