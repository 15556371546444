import { useCallback } from 'react';
import { unsubscribe } from '../../api/unsubscribe';

export const useUnsubscribeModel = () => {
	const unsubscribeLocal = useCallback(
		async (token: string, email: string, feedback: string, resubscribeAt?: number) => {
			return unsubscribe(token, email, feedback, resubscribeAt);
		},
		[]
	);

	return {
		unsubscribe: unsubscribeLocal,
	};
};
