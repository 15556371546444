import { useCallback, useEffect, useState } from 'react';
import { ProfileComponentParams } from '../types';
import {
	registerCandidateInterest,
	getProfileForCandidate,
	ConciergeInterestRequestNegativeFeedback,
	logFeedback,
	ConciergeInterestRequestStatus,
	FetchProfileResponseModel,
} from '../../../api/profile';

const useCandidateRequestViewModel = ({ token }: { token: string }): ProfileComponentParams => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<FetchProfileResponseModel>();
	const [reloadData, setReloadData] = useState(true);

	const fetchProfile = useCallback(async () => {
		setLoading(true);
		const response = await getProfileForCandidate(token);

		if (response) {
			setData(response);
		}
		setLoading(false);
	}, [setLoading, token]);

	useEffect(() => {
		if (reloadData) {
			fetchProfile();
			setReloadData(false);
		}
	}, [reloadData, fetchProfile]);

	const registerInterestInternal = useCallback(
		async (interested: boolean) => {
			await registerCandidateInterest(token, interested, data?.metadata?.vettingCallLink);
			setReloadData(true);
		},
		[token, data?.metadata?.vettingCallLink]
	);

	const logCandidateNegativeFeedback = useCallback(
		async (reasons: (ConciergeInterestRequestNegativeFeedback | string)[]) => {
			await logFeedback(token, ConciergeInterestRequestStatus.NOT_INTERESTED, reasons);
			setReloadData(true);
		},
		[token]
	);

	const params: ProfileComponentParams = {
		loading,
		data,
		registerInterest: registerInterestInternal,
		logNegativeFeedback: logCandidateNegativeFeedback,
	};

	return params;
};

export default useCandidateRequestViewModel;
