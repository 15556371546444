import type { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ConciergeInterestRequestStatus } from '../../../api/profile';
import ClosedView from '../ClosedView';
import LoadingView from '../LoadingView';
import useClientRequestViewModel from './model';
import ProfileView from '../ProfileView';
import InvalidUrlView from '../InvalidUrlView';
import DisinterestedView from '../DisinterestedView';

const _clientHasResponded = (requestStatus: ConciergeInterestRequestStatus): boolean => {
	switch (requestStatus) {
		case ConciergeInterestRequestStatus.OPEN:
		case ConciergeInterestRequestStatus.FOLLOW_UP_SENT:
		case ConciergeInterestRequestStatus.INTERESTED:
		case ConciergeInterestRequestStatus.NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_CONTACTED:
			return false;
		case ConciergeInterestRequestStatus.CLIENT_INTERESTED:
		case ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED:
		case ConciergeInterestRequestStatus.CONNECTED:
		case ConciergeInterestRequestStatus.CLOSED:
			return true;
	}
};

const ClientRequestViewModel: FC<{ token: string }> = ({ token }) => {
	const { t } = useTranslation();
	const { loading, data, registerInterest, logNegativeFeedback } = useClientRequestViewModel({
		token,
	});

	const { status: requestStatus, clientFeedback } = data?.metadata || {};

	if (loading) {
		return <LoadingView />;
	}

	if (requestStatus === ConciergeInterestRequestStatus.CLOSED) {
		return <ClosedView />;
	}

	if (requestStatus && _clientHasResponded(requestStatus)) {
		return (
			<>
				<Helmet>
					<title>{`${t('matchmaking_name')} - Response Recorded`}</title>
				</Helmet>
				<Row>
					<Col className="text-center p-5">
						Thanks for getting back to us.
						<br />
						<br />
						{requestStatus === ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED ? (
							<DisinterestedView
								showSurvey={!clientFeedback}
								logNegativeFeedback={logNegativeFeedback}
							/>
						) : (
							<>
								We’re glad you like {data?.profile?.firstName ?? 'them'}! We’ll vet them further to
								make sure it’s a match and let you know if we think you should meet.
								<br />
								<br />
								<span style={{ fontSize: 14, color: '#b9b8b8' }}>
									If you have any questions don’t hesitate to reach out to your matchmaker.
								</span>
							</>
						)}
						{data && <ProfileView data={data.profile!} status={data.metadata.status} />}
					</Col>
				</Row>
			</>
		);
	}

	if (!data) {
		return <InvalidUrlView />;
	}

	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - ${data.profile?.firstName}`}</title>
			</Helmet>
			<ProfileView
				data={data.profile}
				status={data.metadata.status}
				registerInterest={registerInterest}
			/>
		</>
	);
};

export default ClientRequestViewModel;
